import styled from "styled-components"

import { GridTable, IGridTable } from "src/ui/GridTable/GridTable"
import { InfoBox } from "src/ui/InfoBox/InfoBox"
import { spacing } from "src/ui/spacing"

export function ParadiseTable({
  header,
  rows,
  templateColumns,
  error,
  onRowClick,
}: {
  error?: {
    hasError: boolean
    title: React.ReactNode
    description?: React.ReactNode
  }
} & Pick<IGridTable, "header" | "rows" | "templateColumns" | "onRowClick">) {
  return (
    <>
      <TableWrapper>
        <GridTable
          header={header}
          rows={rows ?? []}
          mobileRows={rows ?? []}
          templateColumns={templateColumns}
          minWidth={0}
          cellSize="small"
          onRowClick={onRowClick}
        />
      </TableWrapper>
      {error && error.hasError && rows.length === 0 && (
        <InfoBox type="warning" title={error.title ?? "An error occured"}>
          {error.description}
        </InfoBox>
      )}
    </>
  )
}

const TableWrapper = styled.div`
  margin-block: ${spacing.S};
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
`
